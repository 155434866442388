import axios from "axios";

const apiClient = axios.create({
  // baseURL: "https://api.nilfiskfood.com/api/",
  baseURL: "https://api.nilfiskfood.com/api/",
});

apiClient.interceptors.request.use((config) => {
  if (!config.skipAuth) {
    const token = JSON.parse(localStorage.getItem("token")).token;
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  }
  return config;
});

export default apiClient;
