import React from "react";
import { useOutletContext } from "react-router-dom";
import { useMachineSWVersionQuery } from "hooks/machine/useMachineSWVersionQuery";
import RemoveMachineDialog from "./components/RemoveMachineDialog";
import { useSelector } from "react-redux";
import { useUpdateMachineSoftware } from "../../hooks/machine/useUpdateMachineSoftware";
import MachineUpdatedDialog from "./components/MachineUpdatedDialog";
import { enqueueSnackbar } from "notistack";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import semver from "semver";
export default function ViewMachineSettings() {
  const myAccount = useSelector((state) => state.user.user);
  const [removeMachineOpen, setRemoveMachineOpen] = React.useState(false);

  const [openSoftwareUpdatedDialog, setOpenSoftwareUpdatedDialog] =
    React.useState(false);
  const { machineData } = useOutletContext();

  const { data, isLoading, error } = useMachineSWVersionQuery(
    machineData?.serialNumber,
  );
  const {
    mutate: updateMachineSoftware,
    isPending,
    isError,
    isSuccess,
  } = useUpdateMachineSoftware();

  const versions = data?.data?.Response;

  console.log("versions", versions);
  let machineIsUpdating = false;
  let canUpdateMachine = false;
  let canOnlyUpdateBootloader = false;
  let needToUpdateByUsb = false;
  let needToUpdateBootloader = false;

  if (versions !== undefined) {
    const bootloaderVersion = versions?.Bootloader;
    const controllerVersion = versions?.Controller;
    if (
      bootloaderVersion.startsWith("U") ||
      controllerVersion.startsWith("U")
    ) {
      machineIsUpdating = true;
    } else {
      // Remove the 'v' prefix if present
      const cleanBootloaderVersion = bootloaderVersion
        .replace(/^v/, "")
        .replace(/[^0-9.].*$/, "");
      const cleanControllerVersion = controllerVersion
        .replace(/^v/, "")
        .replace(/[^0-9.].*$/, "");

      console.log("cleanBootloaderVersion", cleanBootloaderVersion);
      console.log("cleanControllerVersion", cleanControllerVersion);
      if (
        cleanControllerVersion &&
        cleanControllerVersion !== "" &&
        semver.lt(cleanControllerVersion, "2.0.0")
      ) {
        console.log("Controller version is less than v2.0.0");
        needToUpdateByUsb = true;
      }

      if (
        cleanBootloaderVersion &&
        cleanBootloaderVersion !== "" &&
        semver.lt(cleanBootloaderVersion, "2.0.0")
      ) {
        console.log("Bootloader version is less than v2.0.0");
        needToUpdateBootloader = true;
      }

      if (
        cleanControllerVersion &&
        cleanControllerVersion !== "" &&
        semver.gte(cleanControllerVersion, "2.1.0")
      ) {
        console.log("Controller version is greater than or equal to v2.1.0");
        canUpdateMachine = true;
      }

      if (
        cleanControllerVersion &&
        cleanControllerVersion !== "" &&
        semver.lt(cleanControllerVersion, "2.1.0")
      ) {
        canUpdateMachine = true;
      }
    }
  }
  let canUpdate =
    myAccount &&
    (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");

  let factoryRole = myAccount?.Factories.find(
    (factory) => factory.id === machineData?.FactoryId,
  )?.FactoryUser?.factoryRole;

  if (factoryRole === "Technician") {
    canUpdate = true;
  }

  const updateMachine = () => {
    console.log("update machine");

    updateMachineSoftware(
      { MachineId: machineData?.id },
      {
        onSuccess: (response) => {
          console.log("responseadsdsdsdsds", response);
          setOpenSoftwareUpdatedDialog(true);
        },
        onError: () => {
          enqueueSnackbar(
            <div>
              <p className="text-sm font-medium text-gray-900">
                There was an error updating the software.
              </p>
              <p className="mt-1 text-sm text-gray-500">
                Please try again later.
              </p>
            </div>,
            { variant: "error" },
          );
        },
      },
    );
  };

  console.log("versions", versions);
  return (
    <>
      <MachineUpdatedDialog
        open={openSoftwareUpdatedDialog}
        setOpen={setOpenSoftwareUpdatedDialog}
      />
      <RemoveMachineDialog
        open={removeMachineOpen}
        setOpen={setRemoveMachineOpen}
        machineId={machineData?.id}
        machineName={machineData?.name}
        serialNumber={machineData?.serialNumber}
        factoryId={machineData?.FactoryId}
      />
      <div className="mx-auto max-w-2xl justify-between gap-x-4">
        <div className="w-full ">
          <div className="overflow-hidden bg-white shadow-sm  ring-1 ring-gray-900/5 sm:rounded-2xl">
            <div className="px-4 py-6 sm:px-6">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Software Versions
              </h3>
              {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p> */}
            </div>
            <div className="border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                {isLoading ? (
                  <>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-900">
                        <div className="flex animate-pulse space-x-4">
                          <div className="flex-1 py-1">
                            <div className="h-3 rounded bg-slate-200 "></div>
                          </div>
                        </div>
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <div className="flex animate-pulse space-x-4">
                          <div className="flex-1 py-1">
                            <div className="h-3 w-20 rounded bg-slate-200"></div>
                          </div>
                        </div>
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-900">
                        <div className="flex animate-pulse space-x-4">
                          <div className="flex-1 py-1">
                            <div className="h-3 rounded bg-slate-200 "></div>
                          </div>
                        </div>
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <div className="flex animate-pulse space-x-4">
                          <div className="flex-1 py-1">
                            <div className="h-3 w-20 rounded bg-slate-200"></div>
                          </div>
                        </div>
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-900">
                        <div className="flex animate-pulse space-x-4">
                          <div className="flex-1 py-1">
                            <div className="h-3 rounded bg-slate-200 "></div>
                          </div>
                        </div>
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <div className="flex animate-pulse space-x-4">
                          <div className="flex-1 py-1">
                            <div className="h-3 w-20 rounded bg-slate-200"></div>
                          </div>
                        </div>
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-900">
                        <div className="flex animate-pulse space-x-4">
                          <div className="flex-1 py-1">
                            <div className="h-3 rounded bg-slate-200 "></div>
                          </div>
                        </div>
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <div className="flex animate-pulse space-x-4">
                          <div className="flex-1 py-1">
                            <div className="h-3 w-20 rounded bg-slate-200"></div>
                          </div>
                        </div>
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-900">
                        <div className="flex animate-pulse space-x-4">
                          <div className="flex-1 py-1">
                            <div className="h-3 rounded bg-slate-200 "></div>
                          </div>
                        </div>
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <div className="flex animate-pulse space-x-4">
                          <div className="flex-1 py-1">
                            <div className="h-3 w-20 rounded bg-slate-200"></div>
                          </div>
                        </div>
                      </dd>
                    </div>
                  </>
                ) : (
                  <>
                    {/* // if versions is null or undefined show message that no data is available} */}
                    {versions === null || versions === undefined ? (
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">
                          No data available
                        </dt>
                      </div>
                    ) : null}

                    {versions?.Bootloader !== "NA" && versions && (
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">
                          Bootloader
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {versions?.Bootloader}
                        </dd>
                      </div>
                    )}
                    {versions?.Controller !== "NA" && versions && (
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">
                          Controller
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {versions?.Controller}
                        </dd>
                      </div>
                    )}
                    {versions?.Inverter1 !== "NA" &&
                      versions?.Inverter2 === "NA" &&
                      versions && (
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-900">
                            Inverter
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            V{versions?.Inverter1}
                          </dd>
                        </div>
                      )}
                    {versions?.Inverter1 !== "NA" &&
                      versions?.Inverter2 !== "NA" &&
                      versions && (
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-900">
                            Inverter 1
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {versions?.Inverter1}
                          </dd>
                        </div>
                      )}
                    {versions?.Inverter2 !== "NA" && versions && (
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">
                          Inverter 2
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {versions?.Inverter2}
                        </dd>
                      </div>
                    )}
                    {versions?.Inverter3 !== "NA" && versions && (
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">
                          Inverter 3
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {versions?.Inverter3}
                        </dd>
                      </div>
                    )}
                    {versions?.Inverter4 !== "NA" && versions && (
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">
                          Inverter 4
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {versions?.Inverter4}
                        </dd>
                      </div>
                    )}
                    {versions?.Inverter5 !== "NA" && versions && (
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">
                          Inverter 5
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {versions?.Inverter5}
                        </dd>
                      </div>
                    )}
                    {versions?.Inverter6 !== "NA" && versions && (
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">
                          Inverter 6
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {versions?.Inverter6}
                        </dd>
                      </div>
                    )}
                    {versions?.IoModule1App !== "NA" &&
                      versions?.IoModule2App === "NA" &&
                      versions && (
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-900">
                            Io Module
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {versions?.IoModule1App}
                          </dd>
                        </div>
                      )}
                    {versions?.IoModule1App !== "NA" &&
                      versions?.IoModule2App !== "NA" &&
                      versions && (
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-900">
                            Io Module 1 App
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {versions?.IoModule1App}
                          </dd>
                        </div>
                      )}
                    {versions?.IoModule1Boot !== "NA" &&
                      (versions?.IoModule1Boot !== "" && versions && (
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-900">
                            Io Module 1 Bootloader
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {versions?.IoModule1Boot}
                          </dd>
                        </div>
                      ))}
                    {versions?.IoModule2App !== "NA" && versions && (
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">
                          Io Module 2
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {versions?.IoModule2App}
                        </dd>
                      </div>
                    )}
                    {versions?.IoModule2Boot !== "NA" && versions && (
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">
                          Io Module 2 Boot
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {versions?.IoModule2Boot}
                        </dd>
                      </div>
                    )}
                    {versions?.IoModule3App !== "NA" && versions && (
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">
                          Io Module 3
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {versions?.IoModule3App}
                        </dd>
                      </div>
                    )}
                    {versions?.IoModule3Boot !== "NA" && versions && (
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">
                          Io Module 3 Boot
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {versions?.IoModule3Boot}
                        </dd>
                      </div>
                    )}
                    {/* {versions?.Modbus !== "NA" && versions && (
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">
                          Modbus
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {versions?.Modbus}
                        </dd>
                      </div>
                    )} */}
                  </>
                )}
              </dl>
              <div className="border-t border-gray-100">
                <div className="px-6 py-6">
                  <div className="flex items-center justify-between">
                    <div className="flex text-sm">
                      <a
                        href="#"
                        className="group inline-flex items-center text-gray-500 hover:text-gray-900"
                      >
                        <QuestionMarkCircleIcon
                          className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        <span className="mx-2">
                          {machineIsUpdating ? (
                            <span>
                              The machine is currently updating. Please wait for
                              the update to complete.
                            </span>
                          ) : needToUpdateByUsb ? (
                            <span>
                              The controller version is less than v2.0.0. You
                              can only update the machine using a USB stick or
                              Wi-Fi AP(Access Point).
                            </span>
                          ) : needToUpdateBootloader ? (
                            <span>
                              The bootloader version is less than v2.0.0. We
                              will update the bootloader first.
                            </span>
                          ) : (
                            <span>You can update the machine.</span>
                          )}
                        </span>
                      </a>
                    </div>
                    <button
                      type="button"
                      onClick={() => updateMachine()}
                      className="inline-flex  rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 disabled:cursor-not-allowed disabled:opacity-50"
                      disabled={
                        isLoading ||
                        !versions ||
                        !canUpdate ||
                        isPending ||
                        !canUpdateMachine ||
                        machineIsUpdating
                      }
                    >
                      {needToUpdateBootloader ? "Update Bootloader" : "Update"}
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="border-t border-gray-100">
                <div className="px-6 py-6 text-end">
                  <div className="pb-4 pt-4">
                    <div className="flex text-sm">
                      <a
                        href="#"
                        className="group inline-flex items-center text-gray-500 hover:text-gray-900"
                      >
                        <QuestionMarkCircleIcon
                          className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        <span className="ml-2">
                          Learn more about creating a factory
                        </span>
                      </a>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => updateMachine()}
                    className="inline-flex w-24 rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 disabled:cursor-not-allowed disabled:opacity-50"
                    disabled={isLoading || !versions || !canUpdate || isPending}
                  >
                    Update All
                  </button>
                </div>
              </div> */}
            </div>
          </div>

          {/* <div className="bg-white shadow-sm ring-1 ring-gray-900/5  sm:rounded-2xl my-4">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-base font-semibold leading-6 text-gray-900">last 7 day performance data</h3>
              <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                <div className="max-w-xl text-sm text-gray-500">
                  <p>
                    put here the information that you can find in the mobile app under the drop down information panel
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className="w-full mt-4 sm:mt-0">
          <div className="overflow-hidden bg-white shadow-sm  ring-1 ring-gray-900/5 sm:rounded-2xl">
            <div className="px-4 py-6 sm:px-6">
              <h3 className="text-base font-semibold leading-7 text-gray-900">Running Hours</h3>
            </div>
            <div className="border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  put here the chart with the running hours
                </div>
              </dl>
            </div>
          </div>
          <div className="bg-white shadow-sm ring-1 ring-gray-900/5  sm:rounded-2xl my-4">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-base font-semibold leading-6 text-gray-900">Power Consumption</h3>
              <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                <div className="max-w-xl text-sm text-gray-500">
                  <p>put here the chart with the power consumption from the last 14 days</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
